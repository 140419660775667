@import url('https://fonts.googleapis.com/css?family=Pacifico');
.shayanexpress {
  .cart.minimized {
    background: none;
    border-bottom: none;
    background: white;
    border-bottom: 2px solid black;
  }
  a.checkout-button.button {
    // font-family: 'Pacifico', sans-serif;
    background: red;
    font-weight: 800;
    // box-shadow: 5px 5px 2px solid black;
    // border: 2px solid #5a7e2a;
  }


  .category-name .text {
    font-family: 'Pacifico', sans-serif;
    font-weight: 400;
    font-size: 1.8em;
    background: white;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    color: black;
  }
  .category-name .text::before {
    content: "❍ "
  }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    // margin-top: -2.5em;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-greek-food-background-meze-gyros-souvlaki-fish-pita-greek-salad-tzatziki-assortment-of-feta-646209769.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ve2p0vn8be78zfynazj3);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits .text {
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
  }
}
